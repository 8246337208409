import { Outlet } from "react-router-dom";
import Dsidebar from "../sidebar/Dsidebar";
import Dtopbar from "../topbar/Dtopbar";
import cl from "./Dcontent.module.css";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Dcontent() {
  return (
    <div className={cl.container}>
      <ScrollToTop />
      <Dsidebar />
      <div className={cl.content}>
        <Dtopbar />
        <div className={cl.content_inner}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Dcontent;
