import { useState } from "react";
import cl from "../Dsidebar.module.css";
import { NavLink } from "react-router-dom";

function DropDown({ title, dropDownContainer, closeSideBar }) {
  const [showDropDown, setSDD] = useState(false);
  function toggleDropDown() {
    setSDD(!showDropDown);
  }
  return (
    <div className={cl.nav_item}>
      <div
        className={
          showDropDown
            ? `${cl.dropdown_trigger} ${cl.dropdown_trigger_padding}`
            : cl.dropdown_trigger
        }
        onClick={toggleDropDown}
      >
        <p>{title}</p>
        <div className={cl.arrow_container}>
          <span
            className={
              showDropDown ? `${cl.arrow} ${cl.active_arrow}` : cl.arrow
            }
          >
            <span></span>
            <span></span>
          </span>
        </div>
      </div>
      {showDropDown ? (
        <div className={cl.dropdown_container}>
          {dropDownContainer.map((c) => (
            <NavLink
              end
              key={c.url}
              to={c.url}
              className={({ isActive }) => (isActive ? cl.active : "")}
              onClick={closeSideBar}
            >
              {c.text}
            </NavLink>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default DropDown;
