const dashboardNavData = [
  {
    navItemContainer: "My Dashboard",
    navItems: [{ url: "/dashboard", text: "Dashboard", dropdown: false }]
  },
  
  {
    navItemContainer: "User",
    navItems: [{ url: "users", text: "All Users", dropdown: false }]
  },
  {
    navItemContainer: "Transection",
    navItems: [
      { url: "point-transfer", text: "Point Transfer", dropdown: false },
      { url: "withdraw", text: "Withdraw", dropdown: false }
              ],
  },
  {
    navItemContainer: "Promotion",
    navItems: [
      { url: "promotion-history", text: "Promotion History", dropdown: false },
      { url: "promotion-achiver", text: "Promotion Achiver - LIVE", dropdown: false },
              ],
  },
  {
    navItemContainer: "Group Sales",
    navItems: [
      { url: "group-sales", text: "Group Sales", dropdown: false },
              ],
  },
  {
    navItemContainer: "Shop & Orders",
    navItems: [
      {
        url: "brands",
        text: "Brands",
        dropdown: false
      },
      {
        url: "category",
        text: "Categories",
        dropdown: false
      },
      {
        url: "products",
        text: "Products",
        dropdown: false
      },
      {
        url: "orders",
        text: "Orders",
        dropdown: false
      }
    ]
  },
];

export default dashboardNavData;
