import cl from "../Dsidebar.module.css";
import { NavLink } from "react-router-dom";

function Single({ url, text, closeSideBar }) {
  return (
    <div className={cl.nav_item}>
      <NavLink
        end
        to={url}
        className={({ isActive }) => (isActive ? cl.single_active : "")}
        onClick={closeSideBar}
      >
        {text}
      </NavLink>
    </div>
  );
}

export default Single;
