import cl from "./Dtopbar.module.css";

import logo from "../../../assets/identity/icon-logo-l.png";

import t1 from "../../../assets/icons/dashboard/topbar/menu_5259008.png";
import t2 from "../../../assets/icons/dashboard/topbar/close_1828774.png";
import t3 from "../../../assets/icons/dashboard/topbar/bell_5647383.png";
import t4 from "../../../assets/icons/dashboard/topbar/chat_3114553.png";
import icon2 from "../../../assets/icons/header/cart-shopping-solid.svg";
import t5 from "../../../assets/icons/dashboard/topbar/logout_1828427.png";
import { NavLink } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";

function Dtopbar() {
  const logOut = async () => {
    try {
      localStorage.clear();
    } catch (error) {
      toast.error("Log out failed!");
    }
  };
  const localData = JSON.parse(localStorage.getItem("userData"));
  function toggleSideBar() {
    document.querySelector("#dashboardSideBar").classList.toggle(cl.visible);
    document.querySelector("#dham").classList.toggle(cl.display);
    document.querySelector("#dcross").classList.toggle(cl.display);
  }
  return (
    <div className={cl.container}>
      <Toaster position="bottom-center" />
      <div className={cl.sidebar_trigger} onClick={toggleSideBar}>
        <button id="dham">
          <img src={t1} alt="Icon" />
        </button>
        <button id="dcross" className={cl.display}>
          <img src={t2} alt="Icon" />
        </button>
      </div>
      <div className={cl.logo}>
        <img src={logo} alt="Icon" />
      </div>
      <div className={cl.shortcuts}>
        <ul>
          <li>
            <NavLink to={"/"}>
              <img src={t3} alt="Icon" />
            </NavLink>
          </li>
          <li>
            <NavLink to={"/"}>
              <img src={t4} alt="Icon" />
            </NavLink>
          </li>
          <li>
            <NavLink to={"/"} onClick={(e) => logOut()}>
              <img src={t5} alt="Icon" />
            </NavLink>
          </li>
          <li>
            <NavLink to={"/cart"}>
              <img src={icon2} alt="Icon" />
            </NavLink>
          </li>
          <li>
            <NavLink to={"profile/" + localData?.data?.user?._id}>
              <img
                className={cl.user}
                src={
                  localData?.data?.user?.profilePhoto ||
                  "https://images.pexels.com/photos/17974423/pexels-photo-17974423/free-photo-of-model-in-blue-headscarf-sitting-on-the-stairs.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load"
                }
                alt="Icon"
              />
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Dtopbar;
